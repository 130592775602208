import React from 'react'
import ContactForm from '../components/contactform'
import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import Srd3 from '../images/srd3.jpg'

import Footer from '../components/footer'
import SubFooter from '../components/subfooter'

const Kontakt = () => (
  <Layout>
    <HeaderSubsite
      headline="Kontakt"
      subline="Schön, dass Sie sich für uns interessieren"
      bg={Srd3}
    />
    <ContactForm />
    <Footer />
    <SubFooter />
  </Layout>
)

export default Kontakt
