import React, { Fragment, Component, useState } from 'react'
import styled, { css } from 'styled-components'
import { Formik, Field, FastField, Form } from 'formik'
import * as Yup from 'yup'
import withField from '@sb-konzept/with-formik-field'

import MegaCopytext from '../megacopytext'

const isFieldInvalid = (...args) => ({ form, field }) =>
  form.errors[field.name] && form.submitCount > 0 && css(...args)

const InputField = styled.input`
  font-family: 'Open Sans';
  background-color: white;
  padding: 0.75rem;
  margin: 0.3125rem;
  border-radius: 0.3125rem;
  display: block;
  vertical-align: middle;
  white-space: normal;
  line-height: 1;
  height: 4rem;
  border: 0;

  :focus {
    outline: 0;
  }

  ${isFieldInvalid`
    box-shadow: red 0px 0px 2px 1px;
  `};
`

const MessageField = styled.textarea`
  font-family: 'Open Sans';
  height: 14.375rem;
  background-color: white;
  padding: 0.75rem;
  margin: 0.3125rem;
  border-radius: 0.3125rem;
  display: block;
  vertical-align: middle;
  white-space: normal;
  line-height: 1;
  border: 0;

  :focus {
    outline: 0;
  }

  ${isFieldInvalid`
    box-shadow: red 0px 0px 2px 1px;
  `};
`

const ConsentLabel = styled.label`
  display: flex;
  margin: 0.3125rem;
  > input[type='checkbox'] {
    margin-right: 0.3125rem;
    margin-top: 0.4rem;
  }
  a {
    color: #fff
    text-decoration: underline;
  }

  ${isFieldInvalid`
    color: red;
    `};


`

const ContactFormContainer = styled.div`
  margin: 0 auto;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
`

const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${InputField} {
    width: calc(50% - 0.625rem);
  }
  @media only screen and (max-width: 600px) {
    ${InputField} {
      width: 100%;
    }
  }
`

const ContactFormOuterContainer = styled.div`
  background-color: #d3a04e;
  padding-top: 2rem;
`

const SubmitButton = styled.button`
  font-family: 'Open Sans';
  background-color: #fff;
  border-radius: 0.625rem;
  padding: 0 4rem;
  margin: 2rem auto 2rem auto;
  font-size: 1.375rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 300ms;
  height: 3.5rem;
  border: 0;
  cursor: pointer;

  &:last-child {
    margin-bottom: 4rem;
  }

  :focus {
    outline: 0;
  }

  :hover {
    background-color: #d6ae62;
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    :hover {
      background-color: #d6ae62;};
    }
  }
`

const ErrorMessage = styled.p`
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1.125rem;
`

const MandatoryLabel = styled.small`
  margin-bottom: 0.625rem;
  padding-left: 0.3125rem;

  color: #fff;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8rem;
`

const FormikInputField = withField(InputField)
const FormikMessageField = withField(MessageField)
const FormikConsentLabel = withField(ConsentLabel)

function ContactForm() {
  const [wasSuccessful, setSuccessful] = useState(false)
  const formId = 'MTo18AptXD1rD28Or6cX'
  return (
    <Formik
      initialValues={{
        company: '',
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        message: '',
        consent1: false,
        consent2: false,
      }}
      validateOnBlur
      validationSchema={Yup.object({
        company: Yup.string(),
        lastName: Yup.string().required(),
        firstName: Yup.string().required(),
        email: Yup.string()
          .email()
          .required(),
        phone: Yup.string().matches(/^\+?\d{1,18}/),
        message: Yup.string().required(),
        consent1: Yup.boolean().oneOf([true]),
        consent2: Yup.boolean().oneOf([true]),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('Entering onSubmit function')
        const response = await fetch(
          `https://europe-west1-sb-mailer.cloudfunctions.net/submitForm/${formId}`,
          {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          }
        )
        const result = await response.json()
        if (result.msg === 'SUCCESS') {
          setSuccessful(true)
        }
        setSubmitting(false)
      }}
    >
      <Form>
        <ContactFormOuterContainer>
          <ContactFormContainer>
            <FastField
              component={FormikInputField}
              placeholder={'Firma'}
              name="company"
            />
            <InputGroup>
              <FastField
                component={FormikInputField}
                placeholder={'Vorname*'}
                name="firstName"
                autoComplete="given-name"
              />
              <FastField
                component={FormikInputField}
                placeholder={'Nachname*'}
                name="lastName"
                autoComplete="family-name"
              />
            </InputGroup>
            <InputGroup>
              <FastField
                component={FormikInputField}
                placeholder={'Email*'}
                autoComplete="email"
                name="email"
                type="email"
              />
              <FastField
                component={FormikInputField}
                placeholder={'Telefonnummer'}
                autoComplete="tel-national"
                name="phone"
                type="tel"
              />
            </InputGroup>
            <FastField
              component={FormikMessageField}
              placeholder={'Nachricht'}
              name="message"
            />
            <MandatoryLabel>{'*Pflichtfeld'}</MandatoryLabel>

            <FastField name="consent1" component={FormikConsentLabel}>
              <FastField type="checkbox" name="consent1" />
              <MegaCopytext style={{ fontSize: 16 }}>
                Ich bin damit einverstanden, dass die in diesem Formular von
                mir eingetragenen Daten zur Kontaktaufnahme verwendet werden.
              </MegaCopytext>
            </FastField>
            <FastField name="consent2" component={FormikConsentLabel}>
              <FastField type="checkbox" name="consent2" />
              <MegaCopytext style={{ fontSize: 16 }}>
                Mir ist bekannt, dass ich die Nutzung meiner Daten
                nachträglich widerrufen bzw. widersprechen kann. Nähere
                Informationen erhalten Sie über die{' '}
                <a
                  href="/datenschutz"
                  style={{ color: 'white', fontWeight: 900 }}
                >
                  Datenschutzerklärung
                </a>
                .
              </MegaCopytext>
            </FastField>
            <Field
              render={({ form }) => (
                <Fragment>
                  <SubmitButton
                    type="submit"
                    style={{ fontFamily: 'Arial, sans-serif' }}
                  >
                    Abschicken
                  </SubmitButton>
                  {!form.isValid &&
                    form.submitCount > 0 && (
                      <ErrorMessage style={{ color: 'red' }}>
                        Ein Fehler ist aufgetreten
                      </ErrorMessage>
                    )}
                  {wasSuccessful && (
                    <ErrorMessage style={{ color: 'green' }}>
                      Erfolg
                    </ErrorMessage>
                  )}
                </Fragment>
              )}
            />
          </ContactFormContainer>
        </ContactFormOuterContainer>
      </Form>
    </Formik>
  )
}

export default ContactForm
